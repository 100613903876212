import { css, cx } from "@linaria/core";
import { navigate } from "gatsby";
import { useEffect, useState } from "react";
import { ApiProvider, QueryProvider, createApi } from "~/api";
import opportunityHeaderImg from "~/assets/images/opportunity-header.jpg";
import {
  OpportunityContactUsBanner,
  OpportunityResultsList,
  OpportunitySidebar,
} from "~/components";
import { QUERYSTRING } from "~/constants";
import { Routes } from "~/models";
import type { LocationState } from "~/types";
import { Layout, Meta, Section } from "~/ui";
import { boolParam } from "~/utils";

export type OpportunityListLocationState = Omit<LocationState, "state"> & {
  state: {
    areaOfInterest: string;
  };
};

export default function OpportunityList({
  location,
}: {
  location: OpportunityListLocationState;
}): JSX.Element | null {
  const api = createApi(process.env.GATSBY_PUBLIC_ID || "");
  const params = new URLSearchParams(location.search);

  const [youngerVolunteersOnly, setYoungerVolunteersOnly] = useState<boolean>(
    !!boolParam(params.has(QUERYSTRING.YOUNGER_VOLUNTEERS_ONLY))
  );

  const interestArea = params.has(QUERYSTRING.AREAS_OF_INTEREST)
    ? Number(params.get(QUERYSTRING.AREAS_OF_INTEREST))
    : undefined;

  useEffect(() => {
    if (youngerVolunteersOnly) {
      params.set(QUERYSTRING.YOUNGER_VOLUNTEERS_ONLY, "true");
    } else {
      params.delete(QUERYSTRING.YOUNGER_VOLUNTEERS_ONLY);
    }
    navigate(`${location.pathname}?${params.toString()}#results`);
  }, [youngerVolunteersOnly]);

  return (
    <ApiProvider api={api}>
      <QueryProvider>
        <Layout>
          <OpportunityHeader />
          <Section id="results" bgColor="offWhite">
            <div className="row">
              <div className="col-12 col-lg-4 mb-4 mb-lg-0">
                <OpportunitySidebar
                  interestArea={interestArea}
                  youngerVolunteersOnly={youngerVolunteersOnly}
                />
              </div>
              <div className="col-12 col-lg-8">
                <OpportunityResultsHeader
                  youngerVolunteersOnly={youngerVolunteersOnly}
                  setYoungerVolunteersOnly={setYoungerVolunteersOnly}
                />
                <OpportunityResultsList params={params} />
              </div>
            </div>
          </Section>
          <OpportunityContactUsBanner />
        </Layout>
      </QueryProvider>
    </ApiProvider>
  );
}

export const Head = () => {
  return <Meta title="Opportunities" url={Routes.OpportunityList} />;
};

const OpportunityHeader = () => {
  return (
    <Section
      bgImage={opportunityHeaderImg}
      baseBgColorTop="primary"
      roundedCorners={{ "top-left": true }}
      className={cx(
        "position-relative d-flex align-items-end",
        css`
          &:before {
            content: "";
            position: absolute;
            inset: 0;
            border-radius: inherit;
            background-color: rgba(0, 0, 0, 0.3);
          }

          min-height: 50vh;

          @media (min-width: 992px) {
            min-height: 65vh;
          }
        `
      )}
    >
      <div className="position-relative d-inline-block">
        <h1 className="d-flex flex-column text-light mb-0">
          <span>Volunteering</span>
          <span className="display-1 mb-n3">Opportunities</span>
          <span className="text-end">for youth</span>
        </h1>
      </div>
    </Section>
  );
};

interface OpportunityResultsHeaderProps {
  youngerVolunteersOnly: boolean;
  setYoungerVolunteersOnly: React.Dispatch<React.SetStateAction<boolean>>;
}

const OpportunityResultsHeader = (props: OpportunityResultsHeaderProps) => {
  return (
    <section className="d-flex justify-content-end align-items-center gap-2 text-center bg-gray-200 p-2 mb-4">
      <small>Only show opportunities suitable for volunteers 14-18yo</small>
      <div className="form-check form-switch d-flex align-items-center ps-0 mb-0">
        <label className="visually-hidden" htmlFor="showYouthVolunteerSwitch">
          {props.youngerVolunteersOnly ? "Show" : "Hide"} youth volunteers
        </label>
        <input
          id="showYouthVolunteerSwitch"
          type="checkbox"
          className={cx(
            "form-check-input",
            css`
              &.form-check-input {
                height: 1.5rem;
                width: 2.75rem;
                margin: 0;
              }
            `
          )}
          checked={props.youngerVolunteersOnly}
          onChange={() => props.setYoungerVolunteersOnly((prev) => !prev)}
        />
      </div>
    </section>
  );
};
